/* Utility to make api request */
import qs from 'qs';
import axios from 'axios';
import store from '@/store';
import endpoints from '@/endpoints';

const API_URL = window.cipConfig.VUE_APP_API_ENDPOINT;

class Api {
  /**
   * Using axios to perform ajax
   *
   * @param {string} method Http methods.
   * @param {string} endpoint Key on endpoint.js file.
   * @param {object} params Includes { path: Object, query: Object data: Object } request parameters.
   * @param {object|null} additionalheaders Other endpoint requierd headers.
   *
   * @return {promise}
   */
  static async dispatch(method, endpoint, params = {}, additionalheaders = null) {
    const headers = this.buildHeaders(additionalheaders);
    const url = this.buildEndpoint(
      endpoint,
      params.path || null
    );

    return await axios({
      url,
      method,
      headers,
      params: params.query || null,
      data: params.data,
      ...(params.custom)
    }).then((response) => {
      const { data, status } = response;
      if (!data) {
        return this.buildResponse(false, status);
      }
      return this.buildResponse(
        true,
        status,
        data
      );
    }).catch(({ response }) => {
      return this.buildResponse(
        false,
        response.status,
        response.data.errors || response.data.message
      );
    });
  }

  /**
   * Use axios to build url and open that url
   *
   * @param {string} method Http methods.
   * @param {string} endpoint Key on endpoint.js file.
   * @param {object} params Includes { path: Object, query: Object data: Object } request parameters.
   * @param {object|null} additionalheaders Other endpoint requierd headers.
   *
   * @return {promise}
   */
  static async export(endpoint, params = {}, additionalheaders = null) {
    const headers = this.buildHeaders({
      ...additionalheaders,
      Accept: 'application/vnd.ms-excel'
    });

    const url = this.buildEndpoint(
      endpoint,
      params.path || null
    );

    return await axios({
      url,
      responseType: "arraybuffer",
      method: 'get',
      headers,
      params: params.query || null,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
      data: params.data,
      ...(params.custom)
    }).then(response => {
      let blob = new Blob([response.data], { type: "application/xlsx" });

      const filename = params.filename || 'export.xlsx';

      if (navigator.appVersion.toString().indexOf('.NET') > 0) {
          window.navigator.msSaveBlob(blob, filename);
      } else {
          let link = document.createElementNS('http://www.w3.org/1999/xhtml', 'a');
          // Add the element to the DOM
          link.setAttribute("type", "hidden"); // make it hidden if needed
          link.download = filename;
          link.href = URL.createObjectURL(blob);
          document.body.appendChild(link);
          link.click();
          link.remove();
      }
    });
  }

  /**
   * Build api endpoint response object
   *
   * @param {bool} success Http request status.
   * @param {integer} code Http request status code.
   * @param {mixed|null} response Http request data.
   *
   * @return {string}
   */
  static buildResponse(success, code, response = null) {
    if (!success) {
      return {
        success,
        code,
        response
      };
    }

    const {data, pagination, statistics, metadata} = response;

    return {
      success,
      code,
      data,
      ...(pagination) && { pagination },
      ...(statistics) && { statistics },
      ...(metadata) && { metadata }
    }
  }

  /**
   * Build api endpoint url
   *
   * @param {string} endpoint Vuex context keys
   * @param {string|array|null} params endpoint query parameters needed
   * @param {object|null} query parameters needed for the endpoint
   *
   * @return {string}
   */
  static buildEndpoint(endpoint, params = null, query = null) {
    endpoint = API_URL + (endpoints[endpoint] || endpoint);

    if (params) {
      for (const key of Object.keys(params)) {
        endpoint = endpoint.replace(`{${key}}`, params[key]);
      }
    }

    if (query) {
      endpoint = `${endpoint}?${qs.stringify(query)}`;
    }

    return endpoint;
  }

  /**
   * Build api endpoint headers
   *
   * @param {object} additional Must be added on the existing preset headers
   *
   * @return {object}
   */
  static buildHeaders(additional = null) {
    const { state } = store;
    return {
      'X-localization': state.defaultLanguage ? state.defaultLanguage.toLowerCase() : '',
      'token': state.token,
      ...additional
    };
  }
}

export default Api;
