import "core-js/shim";
import "regenerator-runtime/runtime";

import Vue from "vue";
import VueScrollTo from "vue-scrollto";
import {BootstrapVue, BootstrapVueIcons} from "bootstrap-vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import SimpleBar from "simplebar";
import "simplebar/dist/simplebar.css";
import axios from "axios";
import VueAxios from "vue-axios";
import Vuelidate from "vuelidate";
import interceptorsSetup from "./interceptors";
import toast from "./toast";
import i18n from "./i18n";
import AOS from "aos";
import "aos/dist/aos.css";
import BackToTop from "vue-backtotop";
import moment from 'moment'
import customCss from './services/CustomCss'
import 'vue-search-select/dist/VueSearchSelect.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import VueSanitize from 'vue-sanitize';
import { Main as Mission } from '@services/App/mission';
import Team from '@services/App/TeamManagement/Team';
import VueFilterPluralize from '@libraries/pluralize';

const DEFAULT_DATE_FORMAT = 'DD/MM/YYYY';
const STORY_DEFAULT_DATE_FORMAT = 'DD/MM/YYYY HH:mm:ss';

/**
 * Make sure a configuration file has been loaded
 */
if (!window.hasOwnProperty('cipConfig')) {
  console.error(
    'Config file not loaded or empty.'
    + (process.env.NODE_ENV === 'production' ? '' : ' Try running `npm run create-config` in the CLI')
  );
}

Vue.use(Vuelidate, VueAxios, axios);
Vue.config.devtools = process.env.NODE_ENV !== 'production';
Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueScrollTo);
Vue.use(BackToTop);
Vue.use(toast);
Vue.use(VueFilterPluralize);

Vue.use(VueSanitize, {
  allowedTags: VueSanitize.defaults.allowedTags.concat([
    'img',
    'u',
    's',
    'sup',
    'sub',
    'h1',
    'h2',
    'address'
  ]),
  allowedAttributes: {
    '*': [
      'style',
      'border',
      'cellpadding',
      'cellspacing',
      'title',
      'href',
      'src',
      'name',
      'alt'
    ]
  }
});

AOS.init({
    once: true,
    easing: "ease-in-out",
    duration: 700,
    offset: 0
});
export const eventBus = new Vue();
// call vue axios interceptors
interceptorsSetup();
let entryUrl = null;

// check requirment of authentication for path
router.beforeEach(async(to, from, next) => {
    if (store.state.isLoggedIn) {
        const settings = JSON.parse(store.state.tenantSetting);
        if (store.state.isProfileComplete != 1) {
            if (to.path != '/my-account' && to.path !== '/auth/slo') {
                return next({
                    name: "myAccount"
                });
            }
        } else {
            if (settings.includes('donation') && store.state.userIsDonationReady !== 1 && to.path !== '/setting') {
                return next({
                    name: "setting"
                });
            }
        }
    }
    if (store.state.isLoggedIn) {
        if (entryUrl) {
            const url = entryUrl;
            entryUrl = null;
            return next(url); // goto stored url
        }
    }
    if (to.meta.requiresAuth && !store.state.isLoggedIn) {
        const redirect = window.location.pathname;

        if (store.state.samlSettings
          && store.state.samlSettings.saml_access_only
        ) {
          localStorage.setItem('returnUrl', redirect);
          window.location.href = store.state.samlSettings.sso_url;
          return;
        }

        entryUrl = to.path;
        next({
            name: "login",
            query: {
                'returnUrl': redirect
            }
        });
        return;
    }

    // check for required tenant settings
    if (to.meta.requiredSettings && to.meta.requiredSettings.length) {
        const settings = JSON.parse(store.state.tenantSetting);
        if (settings) {
            if (!to.meta.requiredSettings.every(setting => settings.indexOf(setting) !== -1)) {
                return next({
                    name: '404'
                });
            }
        }
    }

    if ((to.path === "/" || to.path === "/forgot-password" || to.path === "/reset-password") &&
        store.state.isLoggedIn) {
        next({
            name: "home"
        });
        return;
    }
    if (to.meta.checkDonationEligibility) {
      const result = await Mission.isDonationEligible(to.params.missionId);
      if (!result.success || !result.data.eligible) {
        next({
          name: '404'
        });
      }
    }

    if (to.meta.requiredTeamAccessRole) {
      const result = await Team.listTeamUsers({
        teamId: to.params.teamId,
        userId: store.state.userId,
        roles: to.meta.requiredTeamAccessRole,
      });
      if (!result.success || !result.data) {
        next({
          name: '404'
        });
      }
    }

    next();
});
router.afterEach((to) => {
    if (to.path == '/') {
        setTimeout(() => {
            document.body.classList.remove("loader-enable");
        }, 500);
    }
})
Vue.filter('formatDate', (value) => {
    if (value) {
        return moment(String(value)).format(DEFAULT_DATE_FORMAT);
    }
})

Vue.filter('userFormatDate', (date) => {
    const format = store.state.userDateFormat
        ? store.state.userDateFormat
        : store.getters.defaultDateFormat;

    if (date) {
        return moment(date).format(format);
    }
})

Vue.filter('formatStoryDate', (value) => {
    return moment(
        value,
        STORY_DEFAULT_DATE_FORMAT
    ).format(store.getters.defaultDateFormat);
})

Vue.filter('userFormatStoryDate', (value) => {
    const format = store.state.userDateFormat
        ? store.state.userDateFormat
        : store.getters.defaultDateFormat;

    return moment(
        value,
        STORY_DEFAULT_DATE_FORMAT
    ).format(format);
})

Vue.filter('formatDateTime', (value) => {
    return moment(String(value)).format('DD/MM/YYYY, LT');
})

Vue.filter('formatAmount', (value) => {
  return Number(value).toFixed(2);
})

Vue.filter('filterGoal', (value) => {
    return parseInt(value);
})

Vue.filter('formatTime', (value) => {
    return moment(String(value)).format('LT');
})

Vue.filter('firstLetterCapital', (value) => {
    if (value) {
        value = value.toLowerCase();
        return value.charAt(0).toUpperCase() + value.slice(1);
    }
})

Vue.filter('firstLetterSmall', (value) => {
    if (value) {
        return value.toLowerCase();
    }
})


Vue.filter('substring', (value, data) => {
    if (typeof value == 'undefined') {
        return value;
    }
    if (typeof value !== 'string'
      && typeof value.toString === 'function'
    ) {
      value = value.toString();
    }

    if (value.length <= data) {
        return value;
    } else {
        return value.substring(0, data) + "...";
    }
});

Vue.filter('substringWithOutDot', (value, data) => {
    if (typeof value !== 'string'
      && typeof value.toString === 'function'
    ) {
      value = value.toString();
    }

    if (value.length <= data) {
        return value;
    } else {
        return value.substring(0, data);
    }
});

Vue.filter('textBoldNumbers', (value) => {
    if (!value && value !== 0) {
        return null;
    }

    return String(value).replace(/([0-9])/g, "<strong>$1</strong>")
});

Vue.filter('formatTimeSheetTime', (value) => {
    if (value) {
        return moment(String(value)).format('HH:mm');
    }
})

window.addEventListener('storage', function (e) {
    if (event.key === 'logout-event') {
        location.reload();
    }
},false);

Vue.mixin({
    methods: {
        settingEnabled(key) {
            let settingArray = JSON.parse(store.state.tenantSetting)
            if (settingArray != null) {
                if (settingArray.indexOf(key) !== -1) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        },

        /**
         * Get specific language tranlations
         *
         * @param {string} key A language field object key.
         * @param {object} params Contains key like {name: value} for string convertions.
         *
         * @return {string}
         */
        getTrans(key, params = null) {
            let label = store.getters.language || null;
            const fields = key.split('.');
            fields.forEach(field => {
                label = label ? label[field] : null;
            });
            if (params && typeof label === 'string') {
                Object.keys(params).forEach(field => {
                    label = label.replace(new RegExp(`%${field}%`, 'g'), params[field]);
                });
            }
            return label;
        },


        /**
         * Format credit card expiration date MM/YY
         *
         * @param {string} value the value to be formatted
         *
         * @return {string}
        */
        formatExpirationDate(value) {
            return value
                .replace(/^([1-9]\/|[2-9])$/g, '0$1/') // append 0 to single numbers
                .replace(/^(0[1-9]|1[0-2])$/g, '$1/') // allow numbers 11&12 (month)
                .replace(/^([0-1])([3-9])$/g, '0$1/$2') // if number provided is 13 or greater, divide it to month/partial year (15 -> 01/5)
                .replace(/^(0?[1-9]|1[0-2])([0-9]{2})$/g, '$1/$2') // 3 digit numbers to MM/YY
                .replace(/^([0]+)\/|[0]+$/g, '0') // 0/ > 0 and 00 > 0
                .replace(/[^\d\/]|^[\/]*$/g, '') // Allow only digits and slash /
                .replace(/\/\//g, '/'); // prevent entering more than 1 slash after itself
        },

        /**
         * Add padding to main element based on the height of the header
         */
        headerAdj() {
            if (this.headerAdjTimeout) clearTimeout(this.headerAdjTimeout);
            this.headerAdjTimeout = setTimeout(function() {
                const header = document.querySelector('.inner-pages > header');
                const main = document.querySelector('.inner-pages > main');
                if (header && main) {
                    main.style.paddingTop = `${header.offsetHeight}px`;
                }
            }, 300);
        },

        /**
         * Add loading and loaded state
         */
        loadingState(load) {
            if (load) {
                return document.body.classList.add("loader-enable");
            }

            return document.body.classList.remove("loader-enable");
        }
    }
});

Vue.directive('focus', {
    inserted: function (el) {
        el.focus();
    }
})

Vue.directive('numeric', {
    bind: function (el, {value}) {
        // Input handling on all input event options
        ['input', 'keydown', 'keyup', 'mousedown', 'mouseup', 'select', 'contextmenu', 'drop'].forEach(function (event) {
            el.addEventListener(event, function() {
                if (/^\d*$/.test(this.value)) {
                    if (this.value) {
                        this.value = Number(this.value);
                    }
                    this.oldValue = this.value;
                    this.oldSelectionStart = this.selectionStart;
                    this.oldSelectionEnd = this.selectionEnd;
                } else if (this.hasOwnProperty('oldValue')) {
                    this.value = this.oldValue;
                    this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
                } else {
                    this.value = null;
                }
            });
        });
        // Disable typing on not integer key
        el.addEventListener('keypress', function (e) {
            const value = parseInt(e.key);
            if (isNaN(value) && e.key !== undefined) {
                e.preventDefault();
            }
        });
    }
})

new Vue({
    router,
    store,
    BootstrapVue,
    SimpleBar,
    VueScrollTo,
    i18n,
    AOS,
    toast,
    BackToTop,
    VueFilterPluralize,
    render: h => h(App)
}).$mount("#app");
