import axios from 'axios';
import router from './router';
import store from './store';
import constants from './constant';

import {
    getAcceptedUserConsent
} from "./services/service";
import { debounce } from "lodash";

function getCookie(name) {
    const cookie = {};

    document.cookie.split(';').forEach(el => {
        const [key, value] = el.split('=');
        cookie[key.trim()] = value;
    });

    return cookie[name];
}

const recheckConsent = debounce(async function (data) {
    if (
        !store.state.userConsent
        || !store.state.isLoggedIn
        || store.state.checkingConsent
        || localStorage.getItem(constants.USER_CONSENT_KEY)
        || (data && data.check_consent)
    ) {
        return;
    }

    store.state.checkingConsent = true;

    const response = await getAcceptedUserConsent({
        check_consent: true
    });

    store.state.checkingConsent = false;

    if (!response || response.accepted_consent) {
        return;
    }
    
    store.dispatch('logout');
}, 300);

export default function setup() {
    // Add a request interceptor
    axios.interceptors.request.use(function(config) {
        config.withCredentials = true;
        config.headers = {
            ...(config.headers),
            'X-Auth-Token': getCookie('token')
        };

        // Check the updated user consent if it's approved or declined
        recheckConsent(config.data);

        return config;
    }, function(error) {
        return Promise.reject(error);
    });

    // Add a response interceptor
    axios.interceptors.response.use(function(response) {
        if (response && response.data && 'data' in response.data && 'isSuccessfulLogin' in response.data.data && response.data.data.isSuccessfulLogin) {
          store.commit('setIsLoggedIn', true);
        }
        return response;
    }, function(error) {
        // if token/account has expired
        if (error && error.response && error.response.data && error.response.data.errors
            && router.app._route.path !== '/'
            && [401, 400, 403].includes(error.response.data.errors[0].status)
            && [210009, 210010, 210012, 400043, 210014, 210015].includes(error.response.data.errors[0].code)) {
            store.dispatch('logout');
        }
        return Promise.reject(error);
    });
}
