var n = /* @__PURE__ */ ((r) => (r.Persian = "fa", r.Japanese = "ja", r.Korean = "ko", r.Lao = "lo", r.Thai = "th", r.Turkish = "tr", r.Chinese = "zh", r.Catalan = "ca", r.Danish = "da", r.German = "de", r.Greek = "el", r.English = "en", r.Spanish = "es", r.Estonian = "et", r.Basque = "eu", r.Finnish = "fi", r.Faroese = "fo", r.Frisian = "fy", r.Hebrew = "he", r.Hungarian = "hu", r.Italian = "it", r.Norwegian = "nb", r.Dutch = "nl", r.Portuguese = "pt", r.Swedish = "sv", r.Vietnamese = "vi", r.French = "fr", r.PortugueseBrazilian = "pt-BR", r.Latvian = "lv", r.GaelicScottish = "gd", r.Romanian = "ro", r.Lithuanian = "lt", r.Belarusian = "be", r.Bosnian = "bs", r.Croatian = "hr", r.Russian = "ru", r.Serbian = "sr", r.Ukrainian = "uk", r.Czech = "cs", r.Slovak = "sk", r.Polish = "pl", r.Slovenian = "sl", r))(n || {});
class u extends Error {
}
const h = (r, a) => a, f = (r, a, e) => r === 1 ? a : e, l = (r, a, e) => r === 0 || r === 1 ? a : e, z = (r, a, e, i) => r === 0 ? a : r % 10 === 1 && r !== 11 ? e : i, R = (r, a, e, i, t) => r === 1 || r === 11 ? a : r === 2 || r === 12 ? e : r >= 3 && r <= 19 ? i : t, d = (r, a, e, i) => r === 1 ? a : r === 0 || (r %= 100, r >= 1 && r <= 19) ? e : i, S = (r, a, e, i) => (r %= 100, r >= 11 && r <= 19 ? e : (r %= 10, r === 1 ? a : r === 0 ? e : i)), g = (r, a, e, i) => (r %= 100, r >= 5 && r <= 20 ? i : (r %= 10, r === 1 ? a : r >= 2 && r <= 4 ? e : i)), k = (r, a, e, i) => r === 1 ? a : r >= 2 && r <= 4 ? e : i, o = (r, a, e, i) => r === 1 ? a : r >= 12 && r <= 14 ? i : (r %= 10, r >= 2 && r <= 4 ? e : i), m = (r, a, e, i, t) => (r %= 100, r === 1 ? a : r === 2 ? e : r === 3 || r === 4 ? i : t), v = (r, a, e) => {
  switch (a = Math.abs(a), r) {
    case n.Persian:
    case n.Japanese:
    case n.Korean:
    case n.Lao:
    case n.Thai:
    case n.Turkish:
    case n.Chinese:
      return h(a, e[0]);
    case n.Catalan:
    case n.Danish:
    case n.German:
    case n.Greek:
    case n.English:
    case n.Spanish:
    case n.Estonian:
    case n.Basque:
    case n.Finnish:
    case n.Faroese:
    case n.Frisian:
    case n.Hebrew:
    case n.Hungarian:
    case n.Italian:
    case n.Norwegian:
    case n.Dutch:
    case n.Portuguese:
    case n.Swedish:
    case n.Vietnamese:
      return f(a, e[0], e[1]);
    case n.French:
    case n.PortugueseBrazilian:
      return l(a, e[0], e[1]);
    case n.Latvian:
      return z(a, e[0], e[1], e[2]);
    case n.GaelicScottish:
      return R(a, e[0], e[1], e[2], e[3]);
    case n.Romanian:
      return d(a, e[0], e[1], e[2]);
    case n.Lithuanian:
      return S(a, e[0], e[1], e[2]);
    case n.Belarusian:
    case n.Bosnian:
    case n.Croatian:
    case n.Russian:
    case n.Serbian:
    case n.Ukrainian:
      return g(a, e[0], e[1], e[2]);
    case n.Czech:
    case n.Slovak:
      return k(a, e[0], e[1], e[2]);
    case n.Polish:
      return o(a, e[0], e[1], e[2]);
    case n.Slovenian:
      return m(a, e[0], e[1], e[2], e[3]);
    default:
      throw new u(`Method with locale ${r} not implemented`);
  }
}, w = {
  install(r) {
    r.filter("pluralize", v);
  }
};
export {
  w as default,
  v as pluralize
};
