/* List of all new endpoints */
export default {
  countries: 'app/country',
  mission: 'app/mission/{id}',
  missionQuestions: 'app/donation/questions',
  payment: 'app/payments',
  paymentMethods: 'app/user/payment-methods',
  setupPaymentMethods: 'app/user/payment-methods/setup',
  paymentMethod: 'app/user/payment-methods/{id}',
  missionDonationEligible: 'app/mission/{id}/donation-eligible',
  paymentGatewayKey: 'app/payment-gateway-key/{payment_gateway_type}',
  questionAnswers: 'app/donation/questions/answer',
  amountTiers: 'app/user/donations/amount-tiers',
  downloadReceipt: 'app/user/donations/download-receipt/{id}',
  teamUsers: 'app/team-volunteering/users',
  teams: 'app/team-volunteering',
  team: 'app/team-volunteering/{teamId}',
  archive: 'app/team-volunteering/archive',
  subscriptions: 'app/user/donations/subscriptions',
  userApplications: 'app/user/applications',
  prescreeningSteps: 'app/missions/{id}/prescreening-steps',
  matchingGiftStats: 'app/dashboard/matching-gift-statistics',
  employmentTypes: 'app/employment-types',
  missionShifts: 'app/missions/{id}/shifts',
  storeApplication: 'app/mission/{id}/pre-screening',
  listUsers: 'app/user-list',
  createTeam: 'app/team',
  updateTeam: 'app/team/{teamId}',
  teamSetup: 'app/team/setup',
  listTeams: 'app/teams',
  manageTimesheet: 'app/timesheet',
  missionsTimesheetsSummary: 'app/missions/timesheets/summary',
  userApplicationsTimesheet: 'app/user/applications/timesheet',
  updateTeamMemberRole: 'app/team/{teamId}/user/{userId}/update-role',
  deleteMember: 'app/team/{teamId}/user',
  addMember: 'app/team/{teamId}/add',
  listTeamApplications: 'app/team/{teamId}/activities',
  archiveTeam: 'app/team/{teamId}/archive',
  messageTeam: 'app/team/{teamId}/message',
  joinTeam: 'app/team/{teamId}/join',
  leaveTeam: 'app/team/{teamId}/leave',
  cancelApplication: 'app/missions/{missionId}/application/{applicationId}/cancel',
  teamTimesheetRequests: 'app/team/{teamId}/timesheet-requests',
  listAttendance: 'app/team/{teamId}/attendance',
  exportListAttendance: 'app/team/{teamId}/attendance/export',
  dailyAttendance: 'app/team/{teamId}/daily-attendance',
  dailyAttendanceExport: 'app/team/{teamId}/daily-attendance/export',
  preLogin: 'app/pre-login',
  preTransmute: 'app/pre-transmute',
  signConsent: 'app/consent',
  verifyAmount: 'app/verify-amount'
};
