export default Object.freeze({
    YOUTUBE_VIDEO_FORMAT: 'mp4',
    MISSION_TYPE_TIME: 'TIME',
    MISSION_TYPE_GOAL: 'GOAL',
    MISSION_TYPE_DONATION: 'DONATION',
    PASSWORD_MIN_LENGTH: 8,
    MAX_RATINGS: 5,
    RATING_INCREMENT: 0.5,
    RATING_STAR_SIZE: 23,
    NEWEST: 'newest',
    OLDEST: 'oldest',
    LOWEST_AVAILABLE_SEATS: 'lowest_available_seats',
    HIGHEST_AVAILABLE_SEATS: 'highest_available_seats',
    MY_FAVOURITE: 'my_favourite',
    DEADLINE: 'deadline',
    APPROVED: 'APPROVED',
    DECLINED: 'DECLINED',
    AUTOMATICALLY_APPROVED: 'AUTOMATICALLY_APPROVED',
    CANCELLED: 'CANCELLED',
    PENDING: 'PENDING',
    REFUSED: 'REFUSED',
    ABORTED: 'ABORTED',
    WAIT_LIST: 'WAIT_LIST',
    SUBMIT_FOR_APPROVAL:'SUBMIT_FOR_APPROVAL',
    TOTAL_VOTES: 'total_votes',
    SKILLS_ENABLED: 'skills_enabled',
    STORIES_ENABLED: 'stories_enabled',
    DONATION_STORIES: 'donation_stories',
    NEWS_ENABLED: 'news_enabled',
    THEMES_ENABLED: 'themes_enabled',
    POLICIES_ENABLED: 'policies_enabled',
    SORTING_MISSIONS: 'sorting_missions',
    QUICK_ACCESS_FILTERS: 'quick_access_filters',
    TOTAL_HOURS_VOLUNTEERED: 'total_hours_volunteered_in_platform',
    Total_MISSIONS_IN_PLATEFORM: 'total_missions_in_platform',
    TIME_CREDIT_MISSION: 'time_credit_mission',
    USER_CAN_SUBMIT_MISSION: 'user_can_submit_mission',
    SHARE_MISSION_FACEBOOK: 'share_mission_facebook',
    SHARE_MISSION_TWITTER: 'share_mission_twitter',
    SHARE_MISSION_LINKEDIN: 'share_mission_linkedin',
    RECENT_VOLUNTEERES: 'recent_volunteers',
    MISSION_RATINGS: 'mission_ratings',
    INVITE_COLLEAGUE: 'invite_colleague',
    MISSION_COMMENTS: 'mission_comments',
    SHOW_GOAL_OF_MISSION: 'show_goal_of_mission',
    SHOW_CURRENT_STATUS_OF_MISSION: 'show_current_status_of_mission',
    SHOW_REMAINING_DATA_TO_ACHIEVE_GOAL: 'show_remaining_data_to_achieve_goal',
    VOLUNTEERING_HOURS_AUTO_APPROVED: 'volunteering_hours_auto_approved',
    VOLUNTEERING_GOAL_AUTO_APPROVED: 'volunteering_goal_auto_approved',
    MISSION_COMMENT_AUTO_APPROVED: 'mission_comment_auto_approved',
    IS_STORY_AUTO_APPROVED: 'is_story_auto_approved',
    IS_COUNTRY_SELECTION: 'country_selection',
    RELATED_MISSIONS: 'related_missions',
    TIMESHEET_DOCUMENT_UPLOAD: 'timesheet_document_upload',
    FILE_MAX_SIZE_BYTE: 4194304,
    FILE_ALLOWED_FILE_TYPES: ['doc','xls', 'xlsx', 'csv', 'pdf', 'png', 'jpg', 'jpeg'],
    FILE_ALLOWED_MIME_TYPES: [
        'application/msword',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'text/csv',
        'application/pdf',
        'image/png',
        'image/jpeg'
    ],
    RECENT_VOLUNTEERES_PER_PAGE: 9,
    MISSION_DEFAULT_PLACEHOLDER: 'placeholder-img.jpg',
    ALLOWED_PICTURE_MIME_TYPES: ['image/png', 'image/jpeg'],
    ALLOWED_PICTURE_TYPES: ['png', 'jpg', 'jpeg'],
    PUBLISHED_STORY: 'Published',
    DRAFT_STORY: 'Draft',
    DECLINED_STORY: 'Declined',
    PENDING_STORY: 'Pending',
    CONTACT_US: 'contact_us_enabled',
    MESSAGE: 'message_enabled',
    MAX_FILE_NUMBER: 20,
    MISSION_RATING_VOLUNTEER :'mission_rating_volunteer',
    DONATION_ENABLED : 'donation',
    VOLUNTERRING_ENABLED : 'volunteering',
    RECENT_DONORS : 'recent_donors',
    MISSION_IMPACT : 'mission_impact',
    MISSION_REMINDER : 'mission_reminder',
    DONATION_MISSION_RATINGS : 'donation_mission_ratings',
    DONATION_MISSION_COMMENTS : 'donation_mission_comments',
    VOLUNTEERING_GOAL_MISSION : 'volunteering_goal_mission',
    VOLUNTEERING_TIME_MISSION : 'volunteering_time_mission',
    STATE_ENABLED: 'state_selection',
    SETTING_VOLUNTEERING: 'volunteering',
    MESSAGES_ADMIN: 'messages_admin',
    CONTACT_US_ADMIN: 'contact_us_admin',
    DONATION : "donation",
    SETTING_DONATION_MISSION_COMMENTS: 'donation_mission_comments',
    SETTING_QUESTIONNAIRE: 'questionnaire',
    SETTING_ELIGIBILITY_QUIZ: 'eligibility_quiz',
    QUESTION_DROPDOWN_SINGLE: 'dropdown_single',
    QUESTION_DROPDOWN_MULTIPLE: 'dropdown_multiple',
    QUESTION_SINGLE_TEXT: 'single_text',
    QUESTION_MULTI_TEXT: 'multi_text',
    QUESTION_RADIO: 'radio',
    QUESTION_CHECKBOX: 'checkbox',
    SETTING_DONATION : 'donation',
    DONATION_TEST_MODE: 'donation_test_mode',
    ELIGIBILITY: 'ELIGIBILITY',
    PRE_MISSION: 'PRE_MISSION',
    VOLUNTEERING : "volunteering",
    VOLUNTEER_THEME_CLASS: "volunteer-theme",
    DONATION_THEME_CLASS: "donation-theme",
    VOLUNTEER: "volunteer",
    QUESTIONNAIRE: 'questionnaire',
    ELIGIBILITY_QUIZ: 'eligibility_quiz',
    SHIFT: 'SHIFT',
    SHIFTS: 'shifts',
    AMOUNT_DEFAULT_DECIMAL_PLACE: 2,
    TEAM_IMAGE_SIZE_LIMIT: 2000000,
    MATCHING_GIFT_SETTING: 'donation_matching_gift',
    SHIFT_DATE_LIMIT: 7,
    FLEXIBLE: 'FLEXIBLE',
    MULTI_DATE: 'MULTI_DATE',
    ONE_TIME: 'ONE_TIME',
    TEAM_VOLUNTEERING: 'team_volunteering',
    SHIFT_TYPE_ONE_TIME: 'ONE_TIME',
    SHIFT_TYPE_SHIFT: 'SHIFT',
    SHIFT_TYPE_FLEXIBLE: 'FLEXIBLE',
    SHIFT_TYPE_MULTI_DATE: 'MULTI_DATE',
    POST_MISSION: 'POST_MISSION',
    TEAMS: 'TEAMS',
    TEAM: 'TEAM',
    TEAM_ADMIN: 'ADMIN',
    TEAM_LEADER: 'LEADER',
    TEAM_MEMBER: 'MEMBER',
    TEAM_ACCESS_ROLE: {
        ADMIN: 'ADMIN',
        LEADER: 'LEADER',
        MEMBER: 'MEMBER',
    },
    PREVIEW_PROFILE: 'preview_profile',
    VIEW_OTHER_USER_PROFILE: 'view_other_user_profile',
    USER_CONSENT_KEY: 'userConsentLog',
    SSO_LOGIN: 'ssoLogin',
    USER_CONSENT_TYPES: {
        'TC': 'TC', // Terms and Condtions
        'PP': 'PP' // Privacy Policy
    },
    VOLUNTEERING_TEAM_TYPES: {
        'TEAM_BASED': 'TEAM_BASED',
        'TEAM_ONLY': 'TEAM_ONLY',
    },
    DATE_FORMAT_TYPES: {
        'MOMENT_JS': 'moment_js'
    }
});
